@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SF-Pro-Display-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exosoft";
  src: url("./assets/fonts/ExoSoft-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  html {
    font-family: "Exosoft";
  }
}

/* .headerLogo {
  font-family: "Hackman";
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}
